import React from "react"
import { Link, graphql } from 'gatsby';
import * as BlockContent from '@sanity/block-content-to-react'
import styled from "@emotion/styled"
import theme from '../shared/theme'

//this is for using richtext editors based on portable text format like sanity use
const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      )
    }
  }


export const query = graphql`
{
    sanityImpressum {
      headline
      _rawTextImpressum
    }
}
`;

const Impressum = ({data}) => (
  <StyledContent>
        <StyledLink to="/">zurück</StyledLink>
        <h1>{data.sanityImpressum.headline}</h1>
        <BlockContent blocks={data.sanityImpressum._rawTextImpressum} serializers={serializers}/>
  </StyledContent>
)

const StyledContent = styled.div`
  margin: 10vw;

  h1 {
    margin-top: 5vw;
  }

  @media all and (max-width: 768px) {
    font-size: ${theme.sizes.fontSmallScreen};
    line-height: ${theme.sizes.fontLineHeightScreen};
  }
`;

const StyledLink = styled(Link)`
  padding: 0 0 100px 0;
  font-size: ${theme.sizes.fontMediumScreenFooter};
  font-weight: bold;
  color: black;
  font-family: ${theme.fontFamily};

  @media all and (max-width: 768px) {
    font-size: ${theme.sizes.fontSmallScreen};
  }
`;

export default Impressum